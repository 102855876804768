import * as API from "@/services/API"

export default {
    storeUser(payload) {
        return API.apiClient.post("/users/store", payload)
    },
    updateUser(payload, id) {
        return API.apiClient.put(`/users/${id}`, payload)
    },
    enableUser(id) {
        return API.apiClient.put(`/users/enable/${id}`)
    },
    disableUser(id) {
        return API.apiClient.put(`/users/disable/${id}`)
    },
    getUser(id) {
        return API.apiClient.get(`/users/${id}`)
    },
    async getUsers(page, dates_query, filters_query) {
        return API.apiClient.get(
            `/users/?page=${page || 1}${dates_query ? dates_query : ''}${filters_query ? filters_query : ''}`
        )
    },
    async paginateUsers(link, dates_query, filters_query) {
        return API.apiClient.get(
            link + `${dates_query ? dates_query : ''}` + `${filters_query ? filters_query : '' }`
        )
    }
}