import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mitt from 'mitt'
import FlashMessage from '@smartweb/vue-flash-message'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import debounce from './directives/debounce'
import print from './directives/imprimir'
import './assets/style/main.css'

library.add(fas)


const emitter = mitt()
const app = createApp(App)

app.directive('debounce', (el,binding) => debounce(el,binding))
app.directive('print', (el, binding) => {
     print(el, binding)
})

app.config.globalProperties.emitter = emitter
app.use(FlashMessage)
.use(store)
.use(router)
.component("faIcon", FontAwesomeIcon)
.mount('#app')