import * as API from "@/services/API";

export default {
    storeCotizacion(payload) {
        return API.apiClient.post('/cotizaciones/store', payload);
    },
    getCotizacion(id){
        return API.apiClient.get(`/cotizaciones/lista-cotizaciones/${id}`);
    },
    nullateCotz(numCotz){
        return API.apiClient.put(`/cotizaciones/lista-cotizaciones/${numCotz}/anulacion`);
    },
    async getCotizaciones(page, dates_query, filters_query){
        return API.apiClient.get(
            `/cotizaciones/lista-cotizaciones/?page=${page || 1}${dates_query ? dates_query : ''}${filters_query ? filters_query : ''}`
        );
    },
    async paginateCotizaciones(link, dates_query, filters_query) {
        return API.apiClient.get(
            link + `${dates_query ? dates_query : ''}` + `${filters_query ? filters_query : '' }`
        );
    },
}