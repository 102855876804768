import { getError } from "@/utils/helpers"
import OrdenTrabajoService from "@/services/OrdenTrabajoService"

export const namespaced = true

function setPaginatedOrdenesTrabajoAnuladas(commit, response) {
  commit("SET_ORDENES_TRABAJO_ANULADAS", response.data.data)
  commit("SET_META", response.data.meta)
  commit("SET_LINKS", response.data.links)
  
  commit("SET_LOADING", false)
}

export const state = {
  ordenesTrabajoAnuladas: [],
  meta: null,
  links: null,
  dates: null,
  filters: null,
  loading: false,
  error: null
}

export const mutations = {
  SET_ORDENES_TRABAJO_ANULADAS(state, ordenesTrabajoAnuladas) {
    state.ordenesTrabajoAnuladas = ordenesTrabajoAnuladas
  },
  SET_META(state, meta) {
    state.meta = meta
  },
  SET_LINKS(state, links) {
    state.links = links
  },
  SET_DATES(state, dates) {
    state.dates = dates
  },
  SET_FILTERS(state, filters) {
    state.filters = filters
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  }
}

export const actions = {
  async getOrdenesTrabajoAnuladas({commit, getters}, page) {
    commit("SET_LOADING", true)
    try {
      const dates_query = getters.dates_query
      const filters_query = getters.filters_query
      const response = await OrdenTrabajoService.getOrdenesTrabajoAnuladas(page, dates_query, filters_query)
      setPaginatedOrdenesTrabajoAnuladas(commit, response)
    } catch (error) {
      commit("SET_LOADING", false)
      commit("SET_ERROR", getError(error))
    }
  },
  async paginateOrdenesTrabajoAnuladas({commit, getters}, link) {
    commit("SET_LOADING", true)
    try {
      const dates_query = getters.dates_query
      const filters_query = getters.filters_query
      const response = await OrdenTrabajoService.paginateOrdenesTrabajoAnuladas(link, dates_query, filters_query)
      setPaginatedOrdenesTrabajoAnuladas(commit, response)
    } catch(error) {
      commit("SET_LOADING", false)
      commit("SET_ERROR", getError(error))
    }
  },
  setDates({commit}, dates) {
    commit("SET_DATES", dates)
  },
  setFilters({commit}, filters) {
    commit("SET_FILTERS", filters)
  }
}

export const getters = {
  ordenesTrabajoAnuladas: (state) => {
    return state.ordenesTrabajoAnuladas
  },
  meta: (state) => {
    return state.meta
  },
  links: (state) => {
    return state.links
  },
  dates: (state) => {
    return state.dates
  },
  dates_query: (state) => {
    let dates_query = ""
    if (state.dates) {
      dates_query = `&start=${state.dates.start}&end=${state.dates.end}`
    }
    return dates_query
  },
  filters: (state) => {
    return state.filters
  },
  filters_query: (state) => {
    let filters_query = ""
    if (state.filters) {
      for (const [key,filter] of Object.entries(state.filters)) {
        if(!filter.fieldType || filter.fieldType == "group") {
          filters_query += `&filters[]=${key},${filter.value}`
        }else if(filter.fieldType && filter.fieldType == "single"){
          filters_query += `&${key}=${filter.value}`
        }
      }
    }
    return filters_query
  },
  loading: (state) => {
    return state.loading
  },
  error: (state) => {
    return state.error
  }
}