import { createStore } from 'vuex'

import * as auth from "@/store/modules/Auth"
import * as sucursal from "@/store/modules/Sucursal"
import * as user from "@/store/modules/User"
import * as venta from "@/store/modules/Venta"
import * as cotizacion from "@/store/modules/Cotizacion"
import * as ot from "@/store/modules/OT"
import * as metodoPago from "@/store/modules/MetodoPago"
import * as abono from "@/store/modules/Abono"
import * as otsAnuladas from "@/store/modules/OTsAnuladas"
import * as anulacionesAbonos from "@/store/modules/AnulacionesAbonos"

export default createStore({
	strict: true,
	state: {
		sidebarOpen: false,
	},
	mutations: {
		SET_SIDEBAR_OPEN(state, sidebarOpen) {
			state.sidebarOpen = sidebarOpen
		}
	},
	actions: {
		toggleSidebar({ commit, getters }) {
			commit("SET_SIDEBAR_OPEN", !getters.sidebarOpen)
		},
		closeSidebar({ commit }) {
			commit("SET_SIDEBAR_OPEN", false)
		},
		openSidebar({commit }) {
			commit("SET_SIDEBAR_OPEN", true)
		}
	},
	getters: {
		sidebarOpen: state => state.sidebarOpen
	},
	modules: {
		auth,
		sucursal,
		user,
		venta,
		cotizacion,
		ot,
		metodoPago,
		abono,
		otsAnuladas,
		anulacionesAbonos
	}
})