import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import auth from "@/middleware/auth"
import guest from "@/middleware/guest"
import verified from "@/middleware/verified"
import admin from "@/middleware/admin"
import estandar from "@/middleware/estandar"

import middlewarePipeline from "@/router/middlewarePipeline"


const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
    meta: {
      title: 'Dashboard',
      middleware: [auth, verified],
    },
    children: [
      { 
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/dashboard/Home'),
      },
      {
        path: '/mi-perfil',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/dashboard/usuarios/Perfil'),
      },
      {
        path: '/mi-clave',
        name: 'PasswordSet',
        component: () => import(/* webpackChunkName: "password-set" */ '../views/dashboard/usuarios/CambioClave'),
      },
      {
        path: '/usuarios/listar',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../views/dashboard/usuarios/Listar'),
        meta: {
          middleware: [admin]
        }
      },
      {
        path: '/usuarios/registrar',
        name: 'RegisterUser',
        component: () => import(/* webpackChunkName: "register-user" */ '../views/dashboard/usuarios/Crear'),
        meta: {
          middleware: [admin]
        }
      },
      {
        path: '/usuarios/editar/:id',
        name: 'EditUser',
        component: () => import(/* webpackChunkName: "edit-user" */ '../views/dashboard/usuarios/Editar'),
        meta: {
          middleware: [admin]
        }
      },
      {
        path: '/cotizaciones/nueva-cotizacion',
        name: 'NuevaCotizacion',
        component: () => import(/* webpackChunkName: "nueva-cotizacion" */ '../views/dashboard/cotizaciones/NuevaCotizacion'),
        meta: {
          middleware: [estandar]
        }
      },
      {
        path: '/cotizaciones/lista-cotizaciones',
        name: 'ListaCotizaciones',
        component: () => import(/* webpackChunkName: "lista-cotizaciones" */'../views/dashboard/cotizaciones/ListaCotizaciones'),
        meta: {
          middleware: [estandar]
        }
      },
      {
        path: '/ordenes-trabajo/lista-ot',
        name: 'ListaOrdenesTrabajo',
        component: () => import(/* webpackChunkName: "lista-ordenes-de-trabajo" */ '../views/dashboard/ordenes-trabajo/ListaOrdenesTrabajo')
      },
      {
        path: '/ordenes-trabajo/lista-abonos',
        name: 'ListaAbonos',
        component: () => import(/* webpackChunkName: "lista-de-abonos" */ '../views/dashboard/ordenes-trabajo/ListaAbonos')
      },
      {
        path: '/ventas/lista-ventas',
        name: 'ListaVentas',
        component: () => import(/* webpackChunkName: "lista-ventas" */ '../views/dashboard/ventas/ListaVentas'),
        meta: {
          middleware: [estandar]
        }
      },
      {
        path: '/ventas/nueva-venta',
        name: 'NuevaVenta',
        component: () => import(/* webpackChunkName: "nueva-venta" */ '../views/dashboard/ventas/NuevaVenta'),
        meta: {
          middleware: [estandar]
        }
      },
      {
        path: '/reportes/caja-diaria',
        name: 'CajaDiaria',
        component: () => import(/* webpackChunkName: "reporte-caja-diaria" */ '../views/dashboard/reportes/CajaDiaria'),
        meta: {
          middleware: [admin]
        }
      },
      {
        path: '/reportes/ordenes-trabajo',
        name: 'OT',
        component: () => import(/* webpackChunkName: "ordenes-trabajo-saldo" */ '../views/dashboard/reportes/OT'),
        meta: {
          middleware: [admin]
        }
      },
      {
        path: '/papelera/ots',
        name: 'OTsAnuladas',
        component: () => import(/* webpackChunkName: "ots-anuladas" */ '../views/dashboard/papelera/OTsAnuladas'),
        meta: {
          middleware: [admin]
        }
      },
      {
        path: '/papelera/abonos',
        name: 'AbonosAnulados',
        component: () => import(/* webpackChunkName: "abonos-anulados" */ '../views/dashboard/papelera/AbonosAnulados'),
        meta: {
          middleware: [admin]
        }
      },
      {
        path: '/papelera/ventas',
        name: 'ventasAnuladas',
        component: () => import(/* webpackChunkName: "ots-anuladas" */ '../views/dashboard/papelera/VentasAnuladas'),
        meta: {
          middleware: [admin]
        }
      },
    ]
  },
  {
    path: '/email/verify-email',
    name: 'VerifyEmail',
    component: () => import(/* webpackChunkName: "verify-email" */ '../views/VerifyEmail.vue'),
    meta: {
      title: 'Verificar Correo',
      middleware: [guest]
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About'),
    meta: {
      title: 'About',
      middleware: [guest]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
    meta: {
      title: 'Inicio de Sesión',
      middleware: [guest]
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword'),
    meta: {
      title: 'Cambiar Contraseña',
      middleware: [guest]
    }
  },
  {
    path: '/forgot-password',
    name: 'Forgot',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword'),
    meta: {
      title: 'Contraseña Olvidada',
      middleware: [guest]
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "not-found" */ "../views/NotFound"),
  },
  {
    path: '/dashboard',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' || Vanguardia'
  const middleware = to.meta.middleware
  const context = { to, from, next, store}

  if (!middleware) {
    return next()
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export default router
