import * as API from "@/services/API";

export default {
    storeVenta(payload) {
        return API.apiClient.post("/ventas/store", payload);
    },
    getVentas(page, dates_query, filters_query){
        return API.apiClient.get(`/ventas/lista-ventas?page=${page || 1}${dates_query ? dates_query : ''}${filters_query ? filters_query : ''}`);
    },
    getVentasAnuladas(page, dates_query, filters_query){
        return API.apiClient.get(`/ventas/anuladas?page=${page || 1}${dates_query ? dates_query : ''}${filters_query ? filters_query : ''}`);
    },
    getDetalle(num){
        return API.apiClient.get(`/ventas/obtener-detalle/${num}`);
    },
    anularVenta(num){
        return API.apiClient.put(`/ventas/anular-venta/${num}`);
    },
    async paginateVenta(link, dates_query, filters_query) {
        return API.apiClient.get(link +  `${dates_query ? dates_query : ''}` + `${filters_query ? filters_query : '' }`)
    },
    getVentasSinPaginacion(page, dates_query) {
        return API.apiClient.get(`/ventas/obtener-ventas/?page=${page || 1}${dates_query ? dates_query : ''}`);
    },
    getTotales(page, dates_query) {
        return API.apiClient.get(`/ventas/obtener-totales/?page=${page || 1}${dates_query ? dates_query : ''}`);
    },
    getVentasDiarias(fecha) {
        return API.apiClient.get(`/reportes/caja-diaria/ventas/${fecha}`);
    }
}