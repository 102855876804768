import * as API from "@/services/API";

export default {
    storeAbono(payload) {
        return API.apiClient.post('/ordenes-trabajo/lista-ot/abono', payload);
    },
    nullateAbono(idAbono){
        return API.apiClient.put(`/abonos/${idAbono}/anulacion`);
    },
    getAbonosDiarios(fecha) {
        return API.apiClient.get(`/reportes/caja-diaria/abonos/${fecha}`);
    },
    async paginateAbonos(link, dates_query, filters_query) {
        return API.apiClient.get(link +  `${dates_query ? dates_query : ''}` + `${filters_query ? filters_query : '' }`)
    },
    async getAbonos(page, dates_query, filters_query){
        return API.apiClient.get(`/abonos?page=${page || 1}${dates_query ? dates_query : ''}${filters_query ? filters_query : ''}`);
    },
    async getAnulacionesAbonos(page, dates_query, filters_query) {
        return API.apiClient.get(`/papelera/abonos?page=${page || 1}${dates_query ? dates_query : ''}${filters_query ? filters_query : ''}`);
    },
    async paginateAnulacionesAbonos(link, dates_query, filters_query) {
        return API.apiClient.get(link +  `${dates_query ? dates_query : ''}` + `${filters_query ? filters_query : '' }`);
    }
}
