import { getError } from "@/utils/helpers"
import MetodoPagoService from "@/services/MetodoPagoService"

export const namespaced = true

export const state = {
    metodo: null,
    metodos: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_METODO(state, sucursal) {
        state.metodo = sucursal
    },
    SET_METODOS(state, sucursales){
        state.metodos = sucursales
    },
    SET_LOADING(state, loading) {
        state.loading = loading
    },
    SET_ERROR(state, error) {
        state.error = error
    }
}

export const actions = {
    async getMetodos({ commit }) {
        commit("SET_LOADING", true)
        return MetodoPagoService.getMetodos()
            .then((response) => {
                commit("SET_METODOS", response.data)
				commit("SET_LOADING", false)
            })
            .catch((error) => {
                commit("SET_LOADING", false);
				commit("SET_METODOS", null)
				commit("SET_ERROR", getError(error));
            })
    }
}

export const getters = {
    metodo: (state) => {
        return state.metodo
    },
    metodos: (state) => {
        return state.metodos
    },
    loading: (state) => {
        return state.loading
    },
    error: (state) => {
        return state.error
    }
}