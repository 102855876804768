import { getError } from "@/utils/helpers"
import SucursalService from "@/services/SucursalService"

export const namespaced = true

export const state = {
  sucursal: null,
  sucursales: null,
  loading: false,
  error: null
}

export const mutations = {
  SET_SUCURSAL(state, sucursal) {
    state.sucursal = sucursal
  },
  SET_SUCURSALES(state, sucursales){
    state.sucursales = sucursales
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  }
}

export const actions = {
  async getSucursales({ commit }) {
    commit("SET_LOADING", true)
    try {
      const response = await SucursalService.getSucursales()
      commit("SET_SUCURSALES", response.data)
      commit("SET_LOADING", false)
    }catch(error) {
      commit("SET_LOADING", false);
      commit("SET_SUCURSALES", null)
      commit("SET_ERROR", getError(error));
    }
  }
}

export const getters = {
  sucursal: (state) => {
    return state.sucursal
  },
  sucursales: (state) => {
    return state.sucursales
  },
  loading: (state) => {
    return state.loading
  },
  error: (state) => {
    return state.error
  }
}