export default function admin({ next, store }) {
  let authUser = store.getters["auth/authUser"]
  let userRole = store.getters["auth/userRole"]

  if (!authUser) {
    store.dispatch("auth/getAuthUser").then(() => {
      authUser = store.getters["auth/authUser"]
      userRole = store.getters["auth/userRole"]
      if (!authUser) next({ name: "Login" })
      else if (authUser && userRole == "admin") next()
      else next({ name: "NotFound" })
    })
  }
  else if (authUser && userRole == "admin") next()
  else next({ name: "NotFound" })
}